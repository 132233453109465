body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: 0px!important;
}
.VIpgJd-ZVi9od-ORHb-OEVmcd.skiptranslate {
  display: none!important;
}

html[lang="id"] .translateResizeFont {
  font-size: 13px;
  width: 120px;
}
.skiptranslate{
  margin-left: 20px;
}
.goog-te-combo{
  background-color: #5487CF;
  color: #EAF0F9;
  border: none;
  padding: 8px;
  font-size: 15px;
  font-family: sans-serif;
  border-radius: 5px 5px 5px 5px;
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media (min-width: 977px) and (max-width: 1204px) {
  .navbar-resize {
      font-size: 10px !important;
  }
}

@media (min-width: 1295px) and (max-width: 1447px) {
  .navbar-resize {
      font-size: 15px !important;
  }
}

@media (max-width: 1450px) {
  .searchBoxContainer {
    margin-top: 90px;
    align-items: unset !important;
  }
}