/* //App global css... */

html {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

div {
    white-space: pre-line;
    //white-space:pre-wrap;
}

reactStrapButton {
    white-space: pre-line;
    //white-space:pre-wrap;
}
reactStrapButton{
  white-space:pre-line;
  //white-space:pre-wrap;
}
reactStrapButton{
  white-space:pre-line;
  //white-space:pre-wrap;
}

/* //example logo animation... */
.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.a_div_wspl {
    white-space: pre-line;
}

.a_div_wspw {
    white-space: pre-wrap;
}

.a_div_wsnw {
    white-space: nowrap;
}

.a_div_wsn {
    white-space: normal;
}

.a_div_wsp {
    white-space: pre;
}

.a_rt {
    text-align: 'right'
}

.a_lt {
    text-align: 'left'
}

.a_ltr {
    direction: ltr
}

.a_rtl {
    direction: rtl
}

.hide {
    display: 'none'
}

.show {
    display: 'block'
}

.testBox {
    top: 670px;
    left: 986px;
    width: 838px;
    height: 297px;
    background: #8CD882 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;
}

.testButton {
    top: 650px;
    left: 1050px;
    width: 100px;
    height: 38px;
    border: 4px solid #000000;
    border-radius: 30px;
    opacity: 1;
}

.contactDetails_Main {
    /* min-width: '300px';
  text-align: 'right' */
}

.labelStyle {
    /* width: '120px';
  text-align: 'right' */
}

.address {
    /* width: '400px'; */
}


/* //App global grid css... */
.app {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    background: #F0F0F0;
    color: white;
}

.app-header {
    border-width: 0px;
    border-style: solid;
    background: white;
    /* background: #707070;
  background: #909090;
  background: #444444; */

    justify-self: stretch;
    align-self: start;

}

.app-navbar {
    display: none;
    padding-top: 40%;
    color: black;
    background: #F0F0F0 0% 0% no-repeat padding-box;
    border-width: 0px 3px 0px 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-style: solid;
    align-self: stretch;
    text-align: center;
    padding-left: 5px;
    padding-right: 10px;
}

.app-navbar-new-contact {
    padding-top: 37px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-left: 0px;
    padding-right: 10px;
}

.app-navbar-horizontal {
    padding-top: 20px;
    display: block;
}

.app-navbar-icons {
    display: none;
}

.app-main {
    color: white;
    background: #F0F0F0;
    border-width: 0px;
    border-style: solid;
    min-height: 85vh;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 2px;
    margin-right: 2px;
}

.app-sidebar {
    background: #434039;
    border-width: 0px;
    border-style: solid;
    align-self: stretch;
    display: grid;
    grid-template-rows: auto 1fr 100px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
}

.app-sidebar-top {
    padding-left: 5px;
    padding-right: 5px;
}

.app-sidebar-body {
    padding-top: 5px;
    padding-bottom: 5px;
}

.app-sidebar-bottom {
    align-self: end;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 5px;
}

.app-footer {
    background: black;
    border-width: 0px;
    border-style: solid;
    align-self: stretch;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto 3fr auto;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.app-footer-top {
}

.app-footer-body {
    align-self: end;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 100%;
    height: 100%;
}

.app-footer-bottom {
    align-self: end;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
}

.divFooterMsg_textarea {
    width: 98%;
    height: 100%;
    text-align: center;
}


/* //App global grid media query css... */

/* @supports (grid-area: auto) {
  @media screen and (max-width: 40em) {
    use this to QA below media query... max-width: 40em is a good way to QA 40.063 (old setting) media query
    }
} */

/* @supports (grid-area: auto) {
  @media screen and (min-width: 40.063em) {
    old setting was here (40.63em) - made it so only one column in landscape mode for some
    key devices such as iPad and some of the latest larger format iPhones and Android phones
    }
} */

/* @supports (grid-area: auto) {
  @media screen and (max-width: 45em) {
    use this to QA below media query... max-width: 40em is a good way to QA 45.063 media query
    }
} */

/* this media query setting is designed to allow two columns to be displayed in landscape mode for some
key devices such as iPad and some of the latest larger format iPhones and Android phones */
/* @supports (grid-area: auto) {
  @media screen and (min-width: 42.063em) {
        .app {
            display: grid;
            grid-template-columns: 2fr 1fr;
            grid-template-rows: 2fr;
            grid-template-areas: "header header"
                                 "navbar navbar"
                                 "main sidebar"
                                 "footer footer";
        }
        .app-header { grid-area: header; }
        .app-main { grid-area: main; }
        .app-navbar { grid-area: navbar; }
        .app-sidebar { grid-area: sidebar; }
        .app-footer { grid-area: footer; }
    }
} */

/* @media screen and (min-width: 64.063em) { */
@supports (grid-area: auto) {
    @media screen and (min-width: 61.063em) {
        .app {
            display: grid;
            grid-template-columns: 3fr 6fr;
            grid-template-rows: 1fr;
            grid-template-areas: "header header" 
                                 "navbar main" 
                                 "footer footer"
        }

        .noNav {
            grid-template-areas: "header header" 
                                 "main main" 
                                 "footer footer" !important; 
        }

        .noNav > nav {
            display: none;
            visibility: hidden;
        }

        .app-header {
            grid-area: header;
        }

        .app-main {
            grid-area: main;
        }

        .app-navbar {
            grid-area: navbar;
            display: block;
        }

        .app-sidebar {
            grid-area: sidebar;
        }

        .app-footer {
            grid-area: footer;
        }

        .app-navbar-horizontal {
            display: none;
        }

        .app-navbar-icons {
            display: block;
        }
    }
}

@supports (grid-area: auto) {
    @media screen and (min-width: 90.063em) {
        .app {
            display: grid;
            /* grid-template-columns: 180px auto 250px; */
            grid-template-columns:1fr 4fr;
            grid-template-rows: 1fr;
            grid-template-areas: "header header header" "navbar main sidebar" "footer footer footer";
        }

        .app-header {
            grid-area: header;
        }

        .app-main {
            grid-area: main;
        }

        .app-navbar {
            grid-area: navbar;
            display: block;
        }

        .app-sidebar {
            grid-area: sidebar;
        }

        .app-footer {
            grid-area: footer;
        }

        .app-navbar-horizontal {
            display: none;
        }

        .app-navbar-icons {
            display: block;
        }

        .smalltabledashboard {
            margin-left: 20% !important; 
            margin-top: 90px !important; 
        }

        .dashboard {
            max-width: 1200px; 
        }
    }
}

.row-gray {
    background-color: #F0F0F0;
    color: black;
}
.row-white {
    background-color: white;
    color: black;
}

.row-gray2 {
    background-color: #d3d3d3;
    color: black;
}

.row-gray3 {
    background-color: rgba(147, 142, 142, 0.50);
    color: black;
}
.row-header {
    background-color: #707070;
    color: white;
    cursor: unset;
    height: 22px; 
}
.grid-row-report {
    font-size: small;
    text-align: left;
    word-wrap: break-word;
    margin-left: 10%;
    width: 100%;
}

.grid-row {
    font-size: small;
    text-align: left;
    word-wrap: break-word;
    margin-left: -5px;
    width: 100%;    
    /* display: flex;
    justify-content: center;
    align-items: center;  */
}

.invoiceDetailItem { 
    display: flex;
    align-items: center; 
}

.billableItem {
    color: green; 
    font-weight: bold; 
}

.nonBillableItem {
    font-weight: bold;
}

grid-billing-plan {
    border-top: solid 1px black;
}

.grid-billing-plan {
    font-family: 'Open Sans', sans-serif;
}

.billingPlanHeader {
    background-color: white;
    margin-left: -5px;    
    font-family: 'Open Sans', sans-serif; 
}

.billingPlanHeaderText {
    color: black; 
    margin-top: 30px; 
    margin-left: 15px;
    letter-spacing: -0.1px; 
    font-weight: bolder;
    font-size: 24px;
}

.settingsHeaderText {
    color: black;
    margin-top: 15px;
    margin-left: 10px;
    letter-spacing: -0.1px;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    padding-bottom: 16px; 
    padding-left: -6px;
}

.settingsHeaderText2 {
    color: black;
    /*margin-top: 15px;
    margin-left: 10px;*/
    letter-spacing: -0.1px;
    font-size: 20px;
    font-family: 'Open Sans', sans-serif;
    /*padding-bottom: 16px;
    padding-left: -6px;*/
}

.sysUserName {
    margin-top: 7px;
    font-size: 28px;
    color: black;
    font-weight: bold;
    white-space: nowrap;
}

.closeIframeIcon {
    margin-top: -10px; 
    margin-bottom: 5px; 
    float: right; 
    font-size: 40px; 
    cursor: pointer;
    border-radius: 50%;
}

.closeIframeIcon:hover {
    background-color: rgba(9,30,66,.08);
    color: #42526e;
}

.invoicesHeaderText {
    color: black;
    margin-top: 45px;
    margin-left: 15px;
    letter-spacing: -0.1px;
    font-weight: bolder;
    font-size: 24px;
}

.invoicePaymentHeader {
    display: flex;
    align-items: center;
    font-size: 14px;
    color:darkslategray;
}
.invoicePaymentHeaderBottom {
    display: flex;
    align-items:center;
    font-size: 14px;
    color: darkslategray;
    margin-left: -2px;
}

.invoiceModalTable {
    font-family: 'Open Sans', sans-serif; 
}

.billingPlanRow {
    border-bottom: 1px solid #b3b3b3;
    display: flex;
    justify-content: center;
    align-items: center;
}

.billingPlanRowButtons {
    border-bottom: 1px solid #b3b3b3;
    min-height: 45px; 
}

.buttonControlsBillingRow {
    display: flex; 
    justify-content: center;
    align-items: center; 
    margin-top: 20px;
    margin-bottom: 20px; 
}

.viewEditClientName {
    color: #5487CF; 
    font-size: 24px;
    letter-spacing: -1px; 
    margin-left: 15px; 
    margin-top: 5px; 
    margin-bottom: -8px;

}

.padb5 {
    padding-bottom: 5px;
}
.padb10 {
    padding-bottom: 10px;
}
.padb15 {
    padding-bottom: 15px;
}
.padt5 {
    padding-top: 5px;
}
.padt10 {
    padding-top: 10px;
}
.padt15 {
    padding-top: 15px;
}
.padl5 {
    padding-left: 5px;
}
.padl10 {
    padding-left: 10px;
}
.padl15 {
    padding-left: 15px;
}
.padr10 {
    padding-right: 10px;
}
.padr5 {
    padding-right: 5px;
}
.padr15 {
    padding-right: 15px;
}
.ml15 {
    margin-left: 15px;
}
.ml5 {
    margin-left: 5px;
}
.ml1 {
    margin-left: 1px;
}
.ml2 {
    margin-left: 2px;
}
.mt5 {
    margin-top: 5px;
}
.mt15 {
    margin-top: 15px;
}
.mt-5 {
    margin-top: -15px;
}
.mb5 {
    margin-bottom: 5px;
}
.mb10 {
    margin-bottom: 10px;
}
.mb15 {
    margin-bottom: 15px;
}
.mb-5 {
    margin-bottom: -5px;
}

/*#loadingGif {
    background: url('./newloadingsm2.gif') no-repeat center center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999999;*/
    /*background-color: #F0F0F0;
    background-size:auto;*/
    /*background-color: #0000004f;
}*/

#loadingGif {
    background: url('./newloadingsm2.gif') no-repeat fixed center;
    position: absolute;
    top: 0;
    left: 0;
    height: 500%;
    width: 100%;
    z-index: 9999999;
    background-size: auto;
    background-color: #0000004f;
}

.viewEditButtonStyle { 
    margin-top: 7px !important;
    margin-bottom: 7px !important;
    border-width: 1.5px !important;
    padding: 5px 10px !important;
}

.noPaddingButton {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.impersonationName {
    color: #5487CF; 
    font-size: 26px;
    letter-spacing: -1px; 
    margin-left: 15px; 
    margin-top: 5px; 
}

.adminModeText {
    color: #5487CF; 
    margin-left: 40px;
}

grid-header:hover {
    cursor: pointer;
}

grid-column {
    border-width: 0px 2px 0px 0px;
    border-color: black;
    border-style: solid;
}

.reports-header{
    font-family: 'Open Sans', sans-serif;
}

.form-editcontact{
   margin: 8px;
   font-size: small;
   font-family: 'Open Sans', sans-serif; 
}

.templateModalButton {
    font-size: 12px !important;
}

.templateModalButton.disabled, .templateModalButton:disabled {
    background-color: unset !important;
    border-color: unset !important;
    color:unset !important;
    opacity: 0.5;
}

.sc-gqjmRU {
    border-color: black !important;
    color: black !important;
}

.iFTwam {
    border-color: black !important;
    color: black !important; 
}

.sc-cSHVUG, .bwTeTR {
    color: black !important;
    font-family: 'Open Sans', sans-serif !important;
}

.grid-checkboxes {
    display: grid;
    /* text-align: left; */
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
    grid-template-rows: 1fr;
    font-size: small;
    text-align: left;
    border: 1px solid #707070;
    /* margin-left: 12px;
    min-height: 40px;
    margin-left: 20%;
    margin-right: 15%;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 5px; */
    padding-top: 10px;
    color: black;
    background-color: #CADAF2;
    margin-top: 20px; 
    margin-bottom: 10px;
    margin-right: 30%;
    margin-left: 26%;
}

.grid-checkboxes-search {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 0.5fr;
    grid-template-rows: 1fr;
    font-size: small;
    text-align: left;
    border: 1px solid #707070;
    padding-top: 10px;
    color: black;
    background-color: #CADAF2;
    margin-top: 20px; 
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.grid-checkboxes2 {
    font-size: small;
    text-align: left;
}


.grid-checkboxes3 {
    display: grid;
    text-align: left;
    position: relative;
    grid-template-columns: 2fr 5fr 1fr 4fr 1fr 4fr 1fr 5fr 7fr 1fr 6fr 1fr 5fr 1fr 1fr;
    grid-template-rows: 1fr;
    font-size: small;
    text-align: left;
    border: 1px solid #707070;
    margin-left: 15%;
    margin-right: 15%;
    margin-bottom: 15px;
    margin-top: 15px;
    padding: 5px;
    padding-top: 10px;
}

.grid-checkboxes4 {
    display: grid;
    text-align: left;
    position: relative;
    grid-template-columns: 1fr 0.2fr 2fr;
    grid-template-rows: 1fr;
    font-size: small;
    text-align: left;
    margin-left: 12%;
    margin-right: 17%;
    margin-bottom: 5px;
    margin-top: 8px;
    padding: 5px;
    padding-top: 10px;
}

.iconSort {
    margin-left: -3px;
}

.iconSort:hover {
    cursor: pointer;
}

.invoiceRowCol {
    margin-bottom: 0px !important;
}

.labelPush { 
    margin-bottom: 0px !important; 
}

.headerContainer {
    grid-column: 1/3; 
    top: 0; 
    position: absolute; 
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    width: 100%;
}

.headerContainerClient {
    grid-column: 1/4; 
    top: 0; 
    position: absolute; 
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    width: 100%;
}

.headerContainerViewEdit {
    grid-column: 1/5; 
    top: 0; 
    position: absolute; 
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; 
    width: 100%;
}

.viewEditNoTop {
    border-top: none !important;
    text-align: center;
    padding: 16px 0px 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #535353;
    letter-spacing: -0.24px;
    position: relative;
}

.marBot150{
    margin-bottom: 150px;
}
.marBot200 {
    margin-bottom: 200px;
}
.marBot250 {
    margin-bottom: 250px;
}

.viewEditNoTopSearch {
    border-top: none !important;
    text-align: center;
    padding: 16px 0px 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #535353;
    letter-spacing: -0.24px;
    position: relative;
    width: 600px;
}

.clientGridFilter {
    border: 1px solid #707070; 
}

.clientGridCheckboxWrapper {
    min-width: 350px;
    display: grid; 
    grid-template-columns: 0.7fr 0.5fr;
    border: 1px solid #707070;
    border-top: none; 
    text-align: center;
    padding: 16px 0px 5px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #535353;
    letter-spacing: -0.24px;
    position: relative;
}

.clientGridCheckboxWrapperAdj {
        display: grid; 
        grid-template-columns: 0.6fr 0.5fr 0.5fr;
        border: 1px solid #707070;
        border-top: none; 
        text-align: center;
        padding: 16px 0px 5px;
        font-family: 'Open Sans', sans-serif;
        font-weight: 600;
        font-size: 18px;
        color: #535353;
        letter-spacing: -0.24px;
        position: relative;
}

.checkboxHeaderLeftAgreement, .checkboxHeaderLeftClient, .checkboxheaderRightAgreement, .checkboxheaderRightClient {
    position: absolute; 
}

.checkboxHeaderLeftAgreement {
    grid-column: 1/2;
    border-top: 1px solid; 
    width: 100%;
}


.checkboxHeaderLeftClient {
    grid-column: 1/5;
    border-top: 1px solid; 
    width: 100%;
}

.checkboxheaderRightAgreement {
    grid-column: 15/16; 
    border-top: 1px solid; 
    width: 100%; 
}

.headerTitleTextClient {
    grid-column: 1/16; 
    border-top: 1px solid; 
}

.headerTitleTextClient span {
    position: relative;
    display: inline-block;
    background-color: #CADAF2;
    max-width: 250px;
    top: -14px;
    width: 250px;
}

.headerTitleTextAgreement {
    grid-column: 1/16; 
    border-top: 1px solid; 
}

.headerTitleTextAgreement span {
    position: relative;
    display: inline-block;
    background-color: #CADAF2;
    max-width: 300px;
    top: -14px;
    width: 300px;
}

.headerTitleTextSearch {
    grid-column: 1/16; 
    border-top: 1px solid; 
}

.headerTitleTextSearch span {
    position: relative;
    display: inline-block;
    background-color: #CADAF2;
    max-width: 300px;
    top: -14px;
    width: 200px;
}


.filterColumnContactGrid {
    text-align: center;
}

.viewEditContactGrid {
    width: 99.3vw;
}

.clientMGMTGrid {
    width: 99.3vw;
}

.topNav {
    padding-right: 8px;
    padding-left: 8px;
}
.topNav:hover {
    cursor: pointer;
    color: #000000;
}

.navSelect { 
    background-color: #f0f0f0;
    color: #000000;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navSelect:hover {
    color: cornflowerblue;
}

div.MuiToolbar-root.MuiToolbar-regular {
    height: 40px; 
    min-height: 40px;
}

.search-icon {
    margin-top: -33px;
    margin-left: -15px;
    position: absolute;
    background-color: black;
    height: 34px;
    width: 35px;
}

.search-box {
    margin-top: 30px;
    position: relative;
    float: right;
    padding-right: 20px;

}

/* .contactGridCheckbox:checked:after {
    content: '\e013';
} */

.smallTextBox {
    border-color: black;
    border-width: 1px;
    border-style: solid;
}

.highlightCell {
    position: absolute; 
    width: 100%;
    height: 100%;
    right: 0px; 
    z-index: -1; 
    /* ADD THE STYLE BELOW BACK IN AND CONDITIONALLY APPLY THIS CLASS FOR HIGHLIGHT CONDITIONS */
    /* background-color: yellow; */
}

.loginPage {
    height: 100vh;
    width: 100vw;
    background-image: url("acadmin_background_medium.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginForm {
    border-radius: 40px;
    background-color: white;
    color: black;
    font-size: small;
    width: 476px;
    height: 418px;
    padding: 12px;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;

}

.modalDialog {
    border-radius: 40px;
    background-color: cornflowerblue;
    color: black;
    font-size: small;
    width: 476px;
    min-height: 288px !important;
    max-height: 418px !important;
    padding: 12px;
    margin-top: 0;
    margin-bottom: 0;
}

.modalDialog2 {
    border-radius: 40px;
    background-color: cornflowerblue;
    color: black;
    font-size: small;
    width: 476px;
    min-height: 288px !important;
    max-height: 80% !important;
    padding: 12px;
    margin-top: 0;
    margin-bottom: 0;
}

div.modalBody.modal-body{
    background-color: #CADAF2;
    border-radius: 33px;
}

div.modal-content {
    background-color: #CADAF2;
    border-radius: 33px;
}

.modalBody {
    background-color: #CADAF2;
    border-color: transparent;
    border-width: unset;
}

.modalBody2 {
    background-color: #CADAF2;
    border-color: transparent;
    border-width: unset;
    font-size: 18px;
    border-radius: 33px;
}

.modalTextArea{
    height: 135px;
}

#lastFullBill {
    border: 2px solid black;
    background-color: #77777752;
    font-weight: bold;
    width: 100%;
}

.sysNotificationHeader{
    margin-top: 30px;
    border: solid;
    border-width: 2px;
}

.sysNotificationHeader2 {
    border-top: solid;
    border-top-width: 1px;
    border-bottom: solid;
    border-bottom-width: 1px;
    margin-bottom: 3px;
}

.sysNotificationHeader3 {
    margin-left: -1px !important;
    border-left: solid !important;
    border-left-width: 1px !important;
}

.login-button {
    font-size: 18px;
    border-color: black;
    border-style: solid;
    border-width: 4px;
    border-radius: 30px;
    background-color: white;
    float: right;
    width: 100px;
    height: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
}

.login-button:hover {
    background-color: grey;
    border-color: #d3d3d3;
    color: #d3d3d3;
}


.login-button:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.login-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
    outline:0;
}

.change-pw-button {
    font-size: 14px;
    border-color: black;
    border-style: solid;
    border-width: 4px;
    border-radius: 30px;
    background-color: white;
    float: right;
    width: 150px;
    height: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    margin-left: 5px;
}

.change-pw-button:hover {
    background-color: grey;
    border-color: #d3d3d3;
    color: #d3d3d3;
}

.change-pw-button:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.change-pw-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
    outline:0;
}


.cancel-button {
    font-size: 10px;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    background-color: white;
    float: left;
    width: 90px;
    height: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    margin-left: 5px;
}

    .cancel-button:hover {
        background-color: grey;
        border-color: #d3d3d3;
        color: #d3d3d3;
    }

    .cancel-button:active {
        color: #fff;
        background-color: #0062cc;
        border-color: #005cbf;
    }

    .cancel-button:focus {
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
        outline: 0;
    }

.reset-password-button {
    font-size: 14px;
    border-color: black;
    border-style: solid;
    border-width: 4px;
    border-radius: 30px;
    background-color: white;
    float: right;
    width: 150px;
    height: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    margin-left: 5px;
}

    .reset-password-button:hover {
        background-color: grey;
        border-color: #d3d3d3;
        color: #d3d3d3;
    }

    .reset-password-button:active {
        color: #fff;
        background-color: #0062cc;
        border-color: #005cbf;
    }

    .reset-password-button:focus {
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
        outline: 0;
    }


.dismiss-button {
    font-size: 14px;
    border-color: black;
    border-style: solid;
    border-width: 4px;
    border-radius: 90px;
    background-color: white;
    float: right;
    width: 250px;
    height: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    margin-left: 5px;
}

    .dismiss-button:hover {
        background-color: grey;
        border-color: #d3d3d3;
        color: #d3d3d3;
    }

    .dismiss-button:active {
        color: #fff;
        background-color: #0062cc;
        border-color: #005cbf;
    }

    .dismiss-button:focus {
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
        outline: 0;
    }

.verify-token-button {
    font-size: 18px;
    border-color: black;
    border-style: solid;
    border-width: 4px;
    border-radius: 30px;
    background-color: white;
    width: 150px;
    height: 36px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    float: right;
}

.verify-token-button:hover {
    background-color: grey;
    border-color: #d3d3d3;
    color: #d3d3d3;
}


.verify-token-button:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.verify-token-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
    outline:0;
}

.logout-button{
    color: cornflowerblue;
}
.logout-button:hover {
    cursor: pointer;
}

.input-textbox {
    width: 95%;
}

.clientListBack {
    margin-top: 30px !important;
    margin-left: 15px; 
    border-width: 1.5px;
    padding: 5px 10px !important;
    font-size: 10px !important;
    font-weight: 600 !important;
}

.unimpersonateButton {
    color: #5487CF;
    margin-left: 15px;
    font-size: 24px;
    text-decoration: underline;
}

.unimpersonateContainer {
    margin-top: -5px;
    margin-bottom: 10px;
}

.unimpersonateContainerNav {
    margin-left: 26px !important; 
}

.unimpersonateButton:hover {
    cursor: pointer;
}
.unimpersonateButton span {
    display: none;
}
.unimpersonateButton:hover span{
    display: inline-block;
}

.clientDetailsBtn {
    border-width: 1.5px;
    padding: px 10px !important;
    font-size: 10px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif;
}

.searchResultsImpersonateButton {
    border-width: 1.5px;
    font-size: 10px !important;
    font-weight: 600 !important;
    font-family: 'Open Sans', sans-serif;
    margin: auto;
    margin-left: 5px;
    margin-right: 5px;
    width:
}

.clientDetailsSearchCol {
    padding-left: 0px; 
    font-size: 9px !important;
}

#clientDetailsSearchButton {
    padding: 5px 5px !important; 
}

.selectAContact { 
    text-decoration: underline;
}

.selectPhotoEditProfile {
    border-width: 1.5px;
    padding: 5px 10px !important;
    font-size: 12px !important;
    font-weight: 600 !important;
}

.editProfileLogoRow {
    margin-bottom: -24px;
}

.editProfileIncludeLogo{
    margin-bottom: 2rem; 
}

.includeLogoCheckbox {
    margin-top: 25px; 
}


.swal-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-gridrow {
    font-size: xx-small;
    font-weight: 700;
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    color: black;
    background-color: unset;
    border-color: black;
    border-radius: 12px;
    border-style: solid;
    border-width: 2px;
}

.button-gridrow:hover {
    background-color: gray;
    border-color: lightgray;
    color: lightgray;
}

.button-gridrow:focus{
    border-color: unset;
    background-color:unset;
    outline-color: unset;
    lighting-color: unset;
    flood-color: unset;
    text-emphasis-color: unset;
}

.button-gridrow-new-contact {
    font-size: 12px;
    color: black;
    min-width: 85px;
    height: 28px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    line-height: normal;
}
.button-gridrow-new-contact:hover {
    background-color: gray;
    border-color: lightgray;
    color: lightgray;
}
.button-gridrow-new-contact:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.button-gridrow-new-contact:focus {
    outline:0;
}


.button-gridrow-send-stripe-email {
    font-size: 9px;
    color: black;
    min-width: 95px;
    min-height: 28px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    cursor: pointer;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 3px;
    border-radius: 20px;
    line-height: normal;
}
.button-gridrow-send-stripe-email:hover {
    background-color: gray;
    border-color: lightgray;
    color: lightgray;
}
.button-gridrow-send-stripe-email:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}
.button-gridrow-send-stripe-email:focus {
    outline: 0;
}

.button-gridrow-invdetails {
    font-size: 12px;
    color: black;
    min-width: 85px;
    height: 28px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    line-height: normal;
}

.button-gridrow-invdetails:hover {
    background-color: gray;
    border-color: lightgray;
    color: lightgray;
}


.button-gridrow-invdetails:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.button-gridrow-invdetails:focus {
    outline: 0;
}


.button-gridrow-invdetailssm {
    font-size: 8px;
    color: black;
    min-width: 65px;
    height: 20px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    line-height: normal;
}

.button-gridrow-invdetailssm:hover {
    background-color: gray;
    border-color: lightgray;
    color: lightgray;
}

.button-gridrow-invdetailssm:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.button-gridrow-invdetailssm:focus {
    outline: 0;
}

.button-action {
    padding: 8px;
    font-size: small;
    width: 100%;
    text-align: center;
    font-weight: unset;
    border-width: 2px;
    border-radius: 20px;
}

.button-action:hover {
    background-color: #F0F0F0;
    border-color: lightgray;
    color: lightgray;
}

.table-responsive {
    width: 101%;
    min-width: 100%;
    margin-right: unset;
}

.logo {
    font-weight: 700;
    font-size: large;
    margin-left: 5px;
}

.small-text {
    font-weight: 700;
    font-size: 14px;
    padding: 5px;
    font-family: 'Open Sans', sans-serif; 
}
.medium-text {
    font-weight: 700;
    font-size: medium;
    padding-left: 5px;
    padding-top: 5px;
    padding-right: 5px;
}
.large-text {
    font-size: x-large;
    font-weight: 700;
    padding: 5px;
}

.searchBar {
    width: 100%;
    min-width: 100%;
    display: grid;
    grid-template-columns: 5fr 2fr;
    grid-template: 1fr;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
}

.searchBarLeft{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
}

.searchBox{
    padding: 5.5px;
    background: #000000 0% 0% no-repeat padding-box;
    /* opacity: 1; */
    /* float: right; */
    /* right: 0; */
    /* margin-right: 0px; */
    font-size: 25px;
    position: absolute; 
    right: 15px; 
}

.searchBox:hover {
    background: #545454 0% 0% no-repeat padding-box !important;
    cursor: pointer;
    -webkit-box-shadow:inset 0px 0px 0px 2px #000000;
    -moz-box-shadow:inset 0px 0px 0px 2px #000000;
    box-shadow:inset 0px 0px 0px 2px #000000;
}

.search-box-input {
    width: 25%;
    margin-left: 5px;
    min-width: 160px;
    height: 48px;
    opacity: 1;
    font-family: 'Open Sans', sans-serif;
    font-weight: 100;
    font-size: 16px;
    border: 2px solid #000000;
    text-indent: 10px;
}

.search-box-icon {
    color: white;
    padding: 5.5px;
    background: #000000 0% 0% no-repeat padding-box;
    /* opacity: 1; */
    /* float: right; */
    /* right: 0; */
    /* margin-right: 0px; */
    position: absolute;
    font-size: 25px;
}

.search-box-icon:hover {
    background: #545454 0% 0% no-repeat padding-box !important;
    cursor: pointer;
    -webkit-box-shadow:inset 0px 0px 0px 2px #000000;
    -moz-box-shadow:inset 0px 0px 0px 2px #000000;
    box-shadow:inset 0px 0px 0px 2px #000000;
}

.searchGridColumn {
    display: flex; 
    align-items: center;
    z-index: 0; 
    overflow: hidden;
}

.searchGridColumnv2 {
    display: flex; 
    align-items: center;
    z-index: 0; 
    overflow-wrap: anywhere;
}

.searchGridHeader {
    width: 101% !important;
}

.searchRowHighlight:hover {
    border-top: 1px solid #5487CF;
    border-bottom: 1px solid #5487CF;
    background-color: #CADAF2;
    cursor: pointer;
}

.searchGridModal {
    font-family: 'Open Sans', sans-serif; 
    margin: 20px auto !important;
    margin-left: 100px !important;
    height: 95%;
}

.modal-dialog {
    margin-top: 0px;
    margin-bottom: 0px;
}

.modifyLanguageModal {
    font-family: 'Open Sans', sans-serif; 
}

.languagechangesbox {
    border-top: 1px solid black;
    border-bottom: 1px solid black; 
    margin-bottom: 40px; 
}

.languagechangesheader {
    font-weight: bold; 
}

.languagechangerow {
    border: none !important; 
}

.languagechangerowItem {
    overflow-wrap: break-word;
}

.borderMarkerCol {
    position: absolute;
    width: 100%;
    right: 0;
    height: 78%;
    border-right: 1px solid black; 
}

.invoiceListCol {
    margin-left: -5px !important;
    text-transform: none !important;
}

.clientSearchBox{
  width: 70%;
  min-width: 160px;
  height: 48px;
  opacity: 1;
  font-family: 'Open Sans', sans-serif;
  font-weight: 100;
  position: absolute;
  right: 15px;
  border: 2px solid #000000;
  text-indent: 10px;

}


.clientSearchBox::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #000000;
    opacity: 1; /* Firefox */
  }
  
 .clientSearchBox:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #000000;
  }
  
  .clientSearchBox::-ms-input-placeholder { /* Microsoft Edge */
    color: #000000;
  }

  .clientSearchBox:focus{ 
    outline: none;
  }

  .searchInputFocus:focus {
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
    box-shadow: 0 0 10px rgb(94, 158, 214), 0 0 10px rgb(94, 158, 214), 0 0 15px rgb(94, 158, 214), 0 0 30px rgb(94, 158, 214);
  }


  .contactGridFilter {
      font-family: 'Open Sans', sans-serif;
      font-weight: 600;
      font-size: 18px; 
      color: #535353;
      letter-spacing: -0.24px; 
  }

.searchBoxContainer{
  align-self: stretch;
  display: flex;
  align-items: center;
  position: relative;
}

.acLogo {
  margin-left: -25px;
  width: auto;
  height: 90px;
}

.acLogo-top {
    margin-left: 25px;
    width: 120px;
    height: 80px
}

.acLogoImg {
    width: 120px;
    height: 80px;
    opacity: 1;
}

.clientLogo {
  margin-top: 3px;
  margin-bottom: 3px;
}
.clientLogoImg{
  height: 78px;
  opacity: 1;
}

.addLogoButtonNav {
    color: #007bff !important;
    font-family: 'Open Sans', sans-serif !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    margin-bottom: 0px !important;
}

.userGreeting {
  margin-left: -17px;
  margin-right: 20px;
  text-align: left;
  font: 18px/20px Open Sans;
  font-weight: 300;
  letter-spacing: 0;
  color: #000000;
  opacity: 1;
  white-space: nowrap;
}

.logout-button {
  margin-left: -17px;
  text-align: left;
  font: 26px/22px Open Sans;
  font-weight: 600;
  letter-spacing: 0;
  color: #6AAEE2;
  opacity: 1;
}

.spacerLine{
  margin-top: 2px;
  margin-left: 30px;
  margin-right: 30px;
  width: 0px;
  height: 47px;
  border: 1px solid #707070;
  opacity: 1;
}

.dashIconText{
  width: 122px;
  height: 27px;
  text-align: left;
  font-weight: 600;
  font: 20px/22px Open Sans;
  letter-spacing: -0.1px;
  color: #FFFFFF;
  opacity: 1;
}

.modal-small{
    font-size: small;
}
.hidden {
    visibility: hidden;
    height: 0;
    display: none;
}

.modal-dialog {
    max-width: unset; 
}

.addressRow {
}

.form-control {
    font-size: small;
}

.blue-background {
    background-color: lightsteelblue;
    padding: 5px;
}

.payorrefund {
    margin-top: 7px;
    margin-bottom: 6px;
    font-size: 10px !important;
    background-color: transparent !important;
}

.payorrefund:hover {
    font-size: 10px !important;
    color: cornflowerblue;
    border-color: cornflowerblue;
    background-color: transparent !important;
}

.payorrefundVoid {
    margin-top: 7px;
    margin-bottom: 6px;
    font-size: 10px !important;
    background-color: transparent !important;
}

.payorrefundVoid:hover {
    font-size: 10px !important;
    color: darkred;
    border-color: darkred;
    background-color: lightgray;
    background-color: transparent !important;
}

.payorrefundActions {
    margin-top: 6px;
    font-size: 10px !important;
    /*background-color: transparent !important;*/
    line-height: 1;
   
}

.payorrefundActions:hover {
    border-color: unset !important; 
    color: unset !important;
    background-color: gray !important;
    border-color: lightgray !important;
    color: lightgray !important;
}

.payorrefundActions2 {
    margin-top: 10px;
    font-size: 10px !important;
    background-color: cornflowerblue !important;
    border-color: black !important;
    color: white !important;
    line-height: 1;
}

.payorrefundActions2:hover {
    border-color: unset !important;
    color: unset !important;
    background-color: darkgray !important;
    border-color: black !important;
    color: black !important;
}

.payorrefundClientDetails2 {
    font-size: 10px !important;
    background-color: cornflowerblue !important;
    border-color: black !important;
    color: white !important;
    line-height: 1;
    padding-bottom: 20px !important;
    margin-top: 7px;
    margin-bottom: 7px;
    padding-top: 2px;
}

.payorrefundClientDetails2:hover {
    border-color: unset !important;
    color: unset !important;
    background-color: darkgray !important;
    border-color: black !important;
    color: black !important;
}

.payorrefundDetails{
    font-size: 10px !important;
    background-color: transparent !important;
    line-height: 1;
   
}

.payorrefundDetails {
    border-color: unset !important;
    color: unset !important;
}

.gray-background {
    background-color: #F0F0F0;
    padding: 5px;
}
.full-screen {
    overflow: hidden;
    background-color: #F0F0F0;
    color: black;
    height: 100%;
    margin-left: -4px;
    margin-right: -4px;
    padding: unset;
}
.full-screenModal {
    overflow: hidden;
    background-color: #F0F0F0;
    color: black;
    height: 100%;
    margin-left: -4px;
    margin-right: -4px;
}

.contact-grid-form {
}

.contact-grid-header {
    width: 105%;
    margin-left: -5px;
    margin-right: -10px;
    padding-top: 5px;
    padding-bottom: 5px;
    height: 10%;
    position:  static;
    background-color: #CADAF2;
    color: black;
    min-height: 100px;
}

.ddStyle {
    background: white;
    text-align: left;
    border-width: 2px;
    border-color: #3e3d3d;
    border-radius: 13px;
    color: #141010;
    font-weight: 800;
}

.ddStyle2 {
    background: white;
    min-width: 150px;
    max-width: 250px;
    text-align: center;
    border-color: #3e3d3d;
    border-radius: 33px;
    color: #382c2c;
    font-weight: 800;
}

.contactGridHead {
    display: flex;
    justify-content: left;
    height: 100% !important; 
    white-space: nowrap; 
    align-items: center;
    font-weight: 600
}

.centerUsersCol {
    align-items: center;
    display: flex;
}

.cobrandingCenter {
    margin-left: 25px;
}

.contactGridTableResponsive {
    font-family: 'Open Sans', sans-serif;
}


.labelMargin {
    margin-bottom: 0px;
}


.contactGridLabel {
    margin-bottom: 0;
}

.searchHeader {
    background-color: #5487CF;
    white-space: pre-line;
    font-family: 'Open Sans', sans-serif; 
    font-weight: 500; 
    font-size: 18px; 
    letter-spacing: -0.1px;
    position: relative; 
    height: 40px;
}

.billingPlanDetailHeader {
    background-color: lightgray;
    height: unset;
}



@media only screen 
and (max-width : 1295px) {
    .searchHeader {
        font-size: 14px;
    }
}

@media only screen 
and (max-width : 1075px) {
    .searchHeader {
        font-size: 12px;
    }
    .sysUserName {
        font-size: 20px;
    }
}

.grid-header-detail {
    background-color: lightgray;
}

.col-sm-1 {
    /*padding-right: 0px !important;*/
}

@supports (grid-area: auto) {
    @media screen and (min-width: 600px) {
        div.MuiToolbar-root.MuiToolbar-regular {
          min-height: 1px;
        }
    }
}

.dashboard {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    padding-left: 20px;
    color: black;
}

.small-table {
    border-radius: 24px;
    margin-left: 10%;
    margin-top: 30px;
    height: 200px;
    min-height: 200px;

}

.medium-table {
    border-radius: 24px;
    margin-left: 10%;
    margin-top: 30px;
    height: 250px;
    min-height: 250px;
}

.medium-table {
    border-radius: 12px;
}

.dashboard-header {
    background-color: #5487CF;
    color: white;
    font-size: 21px;
    width: 80%;
    font-weight: 700;
    padding: 8px 5px 8px 20px;
    border-radius: 12px 12px 0px 0px;
    display: flex; 
    align-items: center; 
    font-family: 'Open Sans', sans-serif;
}

.dashboard-footer {
    border-radius: 0px 0px 12px 12px;
}

.dashboard-body {
    background-color: white;
    color: black;
    font-size: small;
    width: 80%;
    padding: 0px 5px 0px 5px;
    min-height: 55px;
    display: flex;
    align-items: center;
    margin-bottom: -7px;
    font-family: 'Open Sans', sans-serif; 
    font-weight: 600; 
    letter-spacing: -0.18px; 
    color: #535353;
}

.dashboard-text {
    text-align: left;
    max-width: 120px;
}

.cardButton {
    padding: 4px 10px !important;
    margin-top: 0px !important;
}

.dangerText {
    color: #F85151 !important;
}

.makeActionButton:hover .tooltiptext {
    visibility: visible;
    opacity: 1;  
}

#divDashboard:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: relative;
}
#divNewContact:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: relative;
}
#divViewContacts:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: relative;
}
#divClientMgt:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: relative;
}
#divActions:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: relative;
}
#divReports:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: relative;
}
#divSettings:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    position: relative;
}

.billableItem:hover .tooltiptextBillable {
    visibility: visible; 
    opacity: 1;
}

.nonBillableItem:hover .tooltiptextBillable {
    visibility: visible;
    opacity: 1;
}

.pdfInvoiceRow:hover .tooltiptextPDF {
    visibility: visible;
    opacity: 1; 
}

.priceColInvoice:hover~.billColInvoice .tooltiptextBillable {
    visibility: visible;
    opacity: 1;
}

.totalColInvoice:hover~.billColInvoice .tooltiptextBillable {
    visibility: visible;
    opacity: 1;
}

.tooltiptext {
    visibility: hidden;
    position: absolute;
    left: 50%;
    bottom: 25px;
    z-index: 1;
    padding: 5px 5px;
    border-radius: 6px;
    text-align: center;
    opacity: 1;  
    transition: opacity 1.5s;  
    background: black;
    color: white;
    /*color: rgba(0, 0, 0, 0.8);*/
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
    padding: 3px 5px;
    font-size: 14px;
}

.tooltiptextBillable {
    visibility: hidden;
    position: absolute;
    left: 100%;
    z-index: 1;
    padding: 5px 5px;
    bottom: 0px; 
    border-radius: 6px;
    text-align: center;
    opacity: 0;  
    transition: opacity 1.5s;  
    background: linear-gradient(#fff, #eee);
    color: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
    padding: 3px 5px;
    font-size: 14px;
    width: 250px; 
}

.tooltiptextResend {
    visibility: hidden;
    position: absolute;
    margin-left: 15px; 
    z-index: 1;
    padding: 5px 5px;
    bottom: 8px; 
    border-radius: 6px;
    text-align: center;
    opacity: 0;  
    transition: opacity 1.5s;  
    background: linear-gradient(#fff, #eee);
    color: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
    padding: 3px 5px;
    font-size: 14px;
    width: 250px; 
}

.tooltiptextPDF {
    visibility: hidden;
    position: absolute;
    left: 37%;
    bottom: -9px;
    z-index: 1;
    opacity: 0;
    text-align: center;
    transition: opacity 1.5s;
    background: linear-gradient(#fff, #eee);
    color: rgba(0, 0, 0, 0.8);
    border-radius: 2px;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6), inset 0 0 0 1px rgba(0, 0, 0, 0.5);
    font-weight: 600; 
}

.makeActionButton:disabled {
    background-color: #F0F0F0 !important;
    border-color: lightgray;
    color: #6d6d6d;
}

.marginZeroButton {
    margin: 0 auto; 
}

.margin3Button {
    margin-bottom: 3px;
    margin-top: 3px;

}

.padr15imp{
    padding-right: 15px !important;
}
.padr10imp {
    padding-right: 10px !important;
}
.padr5imp {
    padding-right: 5px !important;
}

.dashboard-numeric {
    text-align: right;
    font-weight: bold;
    /* min-width:40px; */
}

.lineRightNumber {
    text-align: right;
    font-weight: 700 !important;
}

.dashboard-money {
    text-align: right;
    font-weight: bold;
}

.moneyDashCol {
    text-align: right;
}

.dashboardIcon {
    margin-right: 10px; 
}

.small-table {
    border-radius: 12px;
}

.smalltabledashboard {
    max-width: 512px;
}

.action-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr ;
    padding-left: 20px;
    color: black;
}

.actions-contact {
    color: #5487CF;
    margin-left: 20px;
    margin-top: 12px;
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    letter-spacing: -1px; 
}

.actionsView {
    width: 99.3vw;
}

.details-label {
    color: darkgray;
    font-size: small;
    /*border-bottom-color: black;*/
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.details-label-noborder {
    color: darkgray;
    font-size: small;
}

.details-value {
    color: black;
    min-width: 70px;
    font-size: small;
}

.label-yellow {
    color: darkgoldenrod;
}
.label-green {
    color: green;
    font-weight: 700;
}
.label-darkred {
    color: red;
}
.label-darkgray {
    color: darkslategray;
}
.billingplan-detail-form {
    background-color: ghostwhite;
}

#aspNetSite {
    height: 90vh;
    width: 100%;
}

.wide-table {
    text-transform: uppercase; 
   /* margin-left: -10px;*/
}

.reportGrid {
    margin-top: 12px;
    color: black;
    margin-left: 15px;
}

.report-results {
    width: 100%;
    margin-top: 12px;
    font-family: 'Open Sans', sans-serif;
}

.no-report-results {
    width: 100%;
    margin-left: 15px;
    margin-top: 12px;
    color: red;
    font-family: 'Open Sans', sans-serif;
}

.htmlViewer {
    margin: 10px;
}

.modal-form {
    width: 50vw;
    
}

.border-row {
    border-top-color: black;
    border-top-width: 1px;
    border-top-style: solid;
}

.right-justify {
    float: right;
    margin-right: -100px; 
}

.rs-dropdown-theme {
    --styled-select__background-color: #e5e5e5;;
    --styled-select__font-family: 'Open Sans', sans-serif;
    --styled-select__border-color: #949494;
    --styled-select__border-width: 1px;
    --styled-select__border-style: solid;

  }

.rs-report-theme {
    --styled-select-menu-outer__max-height: 400px;
}

.short-table {
    /* max-height: 65vh; */
    height: 76vh;
    overflow-y: scroll;
    width: 100%;
    margin-left: -5px; 
}

.short-table::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 15px;
    margin-right:1px; 
  }

.short-table::-webkit-scrollbar-thumb {
    background: #5487CF;
    border-radius: 12px;
    cursor: pointer !important;
}

.short-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    background-color: #F5F5F5; 
}


.settingsTableScroll {
    margin-left:0px;
}

.logoURLText {
    width: 100%;
}

.dark-theme {
    --styled-select-placeholder__color: #999;
    --styled-select__color: white;
    --styled-select__background-color: #555;
    --styled-select__border-color: black;
    --styled-select__border-width: 3px;
    --styled-select__border-radius: 5px;
  
    --styled-select-menu-outer__margin: 0 0 0 0;
    --styled-select-menu-outer__padding: 0;
    --styled-select-menu-outer__background-color: #555;
    --styled-select-menu-outer__border-color: black;
    --styled-select-menu-outer__border-style: solid;
    --styled-select-menu-outer__border-width: 3px;
  
    --styled-select-option__background-color: #444;
  
    --styled-select-option__color--focused: #eee;
    --styled-select-option__background-color--focused: #333;
  
    --styled-select-option__color--selected: #eee;
    --styled-select-option__background-color--selected: #444;
  }



/* input[type="date"]::-webkit-calendar-picker-indicator,input[type="month"]::-webkit-calendar-picker-indicator  {
    position: absolute;
    top: 0;
    left: 0;
    right: 45px;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

input[type="date"]::-webkit-calendar-picker-indicator,input[type="month"]::-webkit-calendar-picker-indicator:hover {
    cursor: pointer;
}

input[type="date"]::-webkit-clear-button {
    display: none;
  }

input[type="date"]::-webkit-inner-spin-button { 
    display: none; 
     cursor: pointer;
} 
*/
.user-photo-input {
    /*display: none;*/
}

.user-photo-button {
    font-size: 16px;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 30px;
    background-color: white;
    width: 115px;
    height: 30px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    text-align: center;
}

.user-photo-button:hover {
    background-color: grey;
    border-color: #d3d3d3;
    color: #d3d3d3;
}


.user-photo-button:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.user-photo-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(0,123,255,.5);
    outline:0;
}

.unselect-client-button:hover {
    cursor: pointer;
}
.unselect-client-button span {
    display: none;
}
.unselect-client-button:hover span{
    display: inline-block;
}

.unselect-client-button {
    font-size: 24px;
    text-decoration: underline;
}

.button-gridrow-edit-profile {
    font-size: 12px;
    color: black;
    min-width: 85px;
    height: 28px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 900;
    cursor: pointer;
    background-color: white;
    border-color: black;
    border-style: solid;
    border-width: 2px;
    border-radius: 20px;
    line-height: normal !important;
}

.button-gridrow-edit-profile:hover {
    background-color: gray;
    border-color: lightgray;
    color: lightgray;
}


.button-gridrow-edit-profile:active {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.button-gridrow-edit-profile:focus {
    outline:0;
}

.MuiButtonBase-root:focus {
    outline: none;
}

.mobileNavLogo {
    width: unset;
}


.modal-popup {
    /*width: 390px;*/
    width: 85vw;
    /* max-width: 70vw; */
    margin: auto;
}

.modal-popupPauseRequest {
    width: 390px;
    margin: auto;
}

.modal-cleanup{
    display: flex !important;
    flex-direction: initial;
    height: 94%;
    overflow: hidden;
}

@media (max-width: 850px) {
    .modal-cleanup {
        display: flex !important;
        flex-direction: initial;
        height: 100%;
        overflow: hidden;
    }
}

@media (max-height: 750px) {
    .modal-cleanup {
        display: flex !important;
        flex-direction: initial;
        height: 160%;
        overflow: hidden;
    }
}

.hiddenIFrame {
    width: 1px;
    height: 1px;
    border: none;
}

.graphTextFront {
    z-index: 6;
    width: 100%;
}

.menuSearchBarTopNavRight {
    color: white;
    font-size: 18px;
    font-family: 'Open Sans', sans-serif;
}

.fjUVVc::-webkit-scrollbar {
    -webkit-appearance: none !important;
    width: 7px !important;
  }
  
.fjUVVc::-webkit-scrollbar-thumb {
    border-radius: 4px !important;
    background-color: rgba(0, 0, 0, .5) !important;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5) !important;
  }

.fjUVVc {
    max-height: 248px !important;
}

.react-datepicker__input-container {
    width: 100% !important;
    height: 100% !important;
    font-family: "'Open Sans', sans-serif" !important;
}

.react-datepicker-wrapper {
    width: 100% !important;
    height: 100% !important;
    font-family: "'Open Sans', sans-serif" !important;
}

.billingPlanDateDiv {
    padding-top: 6px;
}

.billingPlanCheckboxLabel {
    margin-bottom: 0;
    padding-bottom: 4px;
    padding-top: 2px;
}

.billingPlanDateDiv > .react-datepicker-wrapper {
    width: 100% !important;
    height: 20px !important;
}   

.billingPlanDateDiv > .react-datepicker-wrapper > .react-datepicker__input-container > input{
    width: 100% !important;
    height: 20px !important;
}   

.billingPlanDateDiv > .react-datepicker-wrapper > .react-datepicker__input-container > span{
    width: 100% !important;
    height: 20px !important;
}   

.billingPlanRowItem {
    white-space: nowrap;
}

.billingPlanRowItemSessions {
    display: flex;
    justify-content: center;
}

.billingPlanDetailTitle {
    font-size: 12px; 
    color: black; 
    display: flex;
    align-items: center;
    font-weight: 600;
}
.billingPlanDetailTitleSmall {
    font-size: 12px; 
    color: black; 
    display: flex;
    align-items: center;
    font-weight: 600;
    text-decoration: underline;
}

.resendInvoice {
    /*line-height: 1;*/
    margin-bottom: 0px;
}

.resendInvoice:hover .tooltiptextResend {
    visibility: visible; 
    opacity: 1;
}

.grid-row[data-is-summary=true] {
    font-weight: bold;
}

.progress-bar-title {
    text-align: center;
    color: black;
}
.progress-bar {
    background-color: lightgray;
    padding: 1px;
}
.progress-bar > div {
    background-color: yellowgreen;
    height: 20px;
    text-align: center;
    vertical-align: middle;
}

@media (max-width: 1400px) {
    .cobrandingLabel {
        margin-left: -16px; 
    }
}

@media (max-width: 1305px) {
    .payorrefundDetailsCol {
       display: flex; 
       justify-content: center;
    }

}

@media(max-width: 1250px) {
    .detailsActionsCol {
        padding-left: 0px !important; 
        padding-right: 0px !important;
    }
}

@media (max-width: 1000px) {
    .payorrefundActions {
        width: 87px;
        min-width: 0px !important;
    }
}
@media (max-width: 1234px) {
    .payorrefundDetails {
        width: 105px; 
    }
}

@media (max-width: 1550px) {
    .contactGridHead {
        font-size: 10px;
    }
    .cobrandingCenter {
        margin-left: 15px; 
    }
}

@media (max-width: 1349px) {
    .contactGridHead {
        font-size: 7px; 
        padding-left: 8px;
    }
}

@media (max-width: 1150px) {
    .action-form {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 970px) {
    .payorrefundDetails {
        width: 88px; 
        font-size: 9px !important; 
    }
    .payorrefundActions {
        font-size: 9px !important; 
    }
}

@media (max-width: 900px) {

    .viewEditButtonEdit {
        justify-content: unset !important; 
        padding-right: 2px;
        padding-left: 2px; 
    }
    .shortTableMobile{
        width: 150%;
    }
    .contactRowMobile {
        width: 160%;
        font-size: 11px;
    }
    .contactGridMobile {
        overflow: scroll !important;
    }
    .wideTableMobile {
        width: 150%;
    }
    /* .clientMgmtMobile {
        overflow: scroll !important;
        overflow-y: scroll !important
    } */
}


@media (max-width: 850px) {
    .cobrandingCenter {
        margin-left: 0px; 
    }
    .viewEditButtonStyle {
        font-size: 8px !important;
    }
    .clientMgmtMobile {
        overflow: scroll !important;
    }
    .report-results {
        overflow: scroll !important
    }
    .searchGridModal {
        width: 95vw !important;
        overflow: scroll !important;
    }
    .searchGridModal {
        width: 95vw !important;
        overflow: scroll !important;
    }
    .mgmtFilterResponsiveDivider {
        border-top: 1px solid black; 
        margin: 0 auto; 
        width: 61%;
    }
    .clientGridCheckboxWrapperAdj {
        margin-top: 30px; 
    }
    .app-main {
        margin-left: 2px;
        margin-right: 2px;
    }
}

@media (max-width: 805px) {
    /* .payorrefundDetails {
        min-width: 0px; 
        width: 58px; 
    } */
    .billingPlanDetailTitle {
        font-size: 10px; 
    }
}

@media (max-width: 750px) {
    .billingPlanRowItemText {
        font-size: 11px; 
    }
}

@media (max-width: 705px) {
    
}

@media (max-width: 675px) {
    .billingPlanDetailTitle {
        font-size: 9px; 
    }
}

@media (min-width: 576px) and (max-width: 675px) {
    .payorrefundActions {
        font-size: 8px !important;
    }
}

@media (max-width: 585px) {
    .shortTableMobile, .wideTableMobile {
        width: 260%;
    }

    .contactRowMobile {
        width: 400%;
    }

    .clientMgmtMobile > .grid-row {
        width: 400%;
    }

    .invoiceModalTable  {
        width: 400%;
    }

    .modal-content > .table-responsive {
        width: 1300px !important;
    }

    .modal-content > .table-responsive > .grid-row{
        width: 1300px !important;
    }

    .modal-content > .searchGridHeader {
        width: 100% !important;
        overflow: auto;
    }

    .modal-content {
        width: 1300px !important;
    }
    .modal-body {
        overflow: auto;
    }
    .searchRowHighlight:hover {
        border-top: unset;
    border-bottom: unset;
    background-color: white;
    }
}

.resetPwdModal {
    border-radius: 93px !important;
    background-color: red !important;
}
.resetPwdModal2 {
    border-radius: 33px !important;
    width: 50%;
    text-align: center;
}

@media (max-width: 575px) {
    .modifyLanguageTextArea {
        width: 100% !important; 
    }
    .payorrefundDetails { 
        margin-bottom: 10px; 
        margin-top: 10px; 
    }
    .payorrefundActions {
        float: left; 
    }
    .payorrefundDetailsCol {
        justify-content: left;
    }
    .billingPlanRowItemSessions {
        justify-content: left;
    }
}

@media (min-width: 881px) {
    .hide-above-850 {
        display: none;
    }
}

@media (max-width: 880px) {
    .loginForm {
        width: 100%;
        height: 438px;
        border-radius: 0;
    }
    .modalDialog {
        width: 100%;
        height: 438px;
        border-radius: 0;
    }
    .modalDialog2 {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }
    .hide-below-850 {
        display: none;
    }
    .logout-button {
        font: 15px Open Sans;
        font-weight: 900;
      }
    .searchBoxContainer {
        min-width: 80px;
    }
    .clientSearchBox {
        width: 90%;
        height: 32px;
        font-size: 14px;
        text-indent: 5px;
    }
    .searchBox {
        height: 32px;
        font-size: 13px;
    }
    .spacerLine {
        margin-left: 10px;
        margin-right: 30px;
    }
    .dashboard {
        grid-template-columns: 1fr;
        grid-template-rows: 25% 25% 25% 25%;
        margin-bottom: 50px; 
    }
    .smalltabledashboard {
        max-width: 2000px;
    }
    .dashboard-header {
        width: 95%;
    }
    .dashboard-body {
        width: 95%;
        font-size: 11px;
    }
    .row {
        margin-right: 0;
    }
    .app {
        width: 100%;
    }
    .app-header {
        width: 100%;
    }
    .app-main {
        width: 100vw;
    }
    .actions-contact {
        font-size: 17px;
    }

    .button-action {
        font-size: 12px;
    }

    .modalLoginButton, .forgotPasswordLink {
        margin: 0 auto;
        width: 300px;
    }

    .forgotPasswordLink {
        color: #243859;
        font-weight: 600;
        text-decoration: underline;
    }

    .loginModalHeader {
        font-weight: 700;
        font-family: 'Montserrat', open-sans;
        line-height: 88px;
        letter-spacing: 2.8px;
        text-align: center;
        color: #243859;
    }

    /* Uncomment to get rid of gray modal-backdrop */
    /* .modal-backdrop.show {
  opacity: 0;
} */

    .rememberme-checkbox {
        margin-left: 10px;
    }

    .rememberme-label {
        padding-left: 5px;
        margin-left: 30px;
    }

    .loginModalFooter {
        text-align: center;
        font-size: 10px;
        color: #243859;
        width: 90%;
        margin: 0 10px 10px;
    }

    .login-modal-input {
        max-width: 400px;
        min-width: 100px;
    }

    .grid-checkboxes {
        margin-left: 0;
        margin-right: 0;
        grid-template-columns: 1fr;
        border: 0px;
    }
    
    .headerTitleTextAgreement {
        border: 0px;
    }
    
    .checkboxHeaderLeftClient {
        border: 0px;
    }

    .grid-checkboxes4 {
        grid-template-columns: 1fr;
        margin-left: 0;
        margin-right: 0;
    }

    .clientGridCheckboxWrapper, .clientGridCheckboxWrapperAdj, .headerContainer {
        grid-template-columns: 1fr;
        border: none;
    }

    .headerTitleTextClient {
        border: none;
    }

    .clientGridCheckboxWrapper {
        min-width: 100%;    
    }
}

.pauseBillingPlanModal {
    min-height: 350px;
}

.pauseBillingPlanModalContents {
    display: flex; 
    justify-content: center;
    align-items: center;
}

.pauseBillingPlanTitle {
    font-family: 'Open Sans', sans-serif; 
    /* font-weight: bold;  */
    font-size: 14px; 
}

.pauseBillingPlanSelect {
    background-color: #e5e5e5;
    border: 1px solid #949494;
    border-radius: 0px;
    height: 32px;
    font-size: 14px;
    font-family: 'Open Sans', sans-serif
}

.paddingBottom5 {
    padding-bottom: 5px;
    padding-right: 13px !important;
}

.pauseBillingPlanModalContainer {
    width: 24%; 
}

.pauseBillingPlanModalContainerv2 {
    width: 70%; 
}

.reportRowData {
    width: 100%;
}

.reportGridData {
    /*overflow: scroll !important;*/
    font-size: 9px;
    text-align: left;
    min-height: 22px;
    display: grid;
    justify-content: start;
    min-width: 80px;
    margin-right: 13px;
}

.reportGridDataExtended {
    font-size: 8px;
    text-align: left;
    min-height: 22px;
    display: grid;
    justify-content: start;
    min-width: 180px;
    margin-right: 13px;
}

.reportGridDataRight {
    /*overflow: scroll !important;*/
    font-size: 9px;
    text-align: right;
    min-height: 22px;
    margin-right: 18px;
}

.report-row-header {
    background-color: #707070;
    color: white;
    cursor: unset;
    /*height: 22px;*/
}

.reportGridHead {
    min-height: 22px;
    display: grid;
    justify-content: stretch;
    font-weight: 600;
    font-size: 12px;
    min-width: 80px;
    padding-left: 15px;
}

.reportGridHeadRight {
    min-height: 22px;
    display: grid;
    justify-content: end;
    font-weight: 600;
    font-size: 12px;
    min-width: 100px;
    padding-left: 5px;
}

.reportIconSort {
    margin-left: -3px;
    font-size: 18px !important;
    text-align: right;
}

.reportIconSort:hover {
    cursor: pointer;
    margin-left: -3px;
    font-size: 18px !important;
    text-align: right;
}